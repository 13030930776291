<template>
  <div class="table-wrap no-wrap-css">
    <!-- Success and Error Message Slot -->
    <div v-if="showMessage" :class="['message', messageType]" class="message-container">
      <slot name="message">{{ message }}</slot>
    </div>

    <div class="parent-wrap">
      <div
        id="mySavedReportsContainer"
        ref="table-scroll"
        class="table-scroll my-saved-reports-container"
      >
        <div>
          <template v-if="!loading">
            <table class="tbl tbl-export" :class="css">
              <thead class="header-top">
                <tr>
                  <th
                    v-if="rowsCollapsible"
                    class="short-column sticky-column-header subRowCollapsible"
                  ></th>

                  <th
                    :ref="`${headers[0].name}_0`"
                    :class="[
                      'headers',
                      { 'sticky-column sticky-column-header': headers[0].isSticky },
                    ]"
                    @mouseover="
                      (el) => handleMouseOver(el, headers[0].name, `${headers[0].name}_0`)
                    "
                    @mouseleave="handleMouseLeave"
                  >
                    <div class="header-container">
                      <div class="header-row">
                        <span class="header-text">{{ headers[0].name }}</span>
                        <div @click="toggleFilter">
                          <font-awesome-icon
                            class="filter-icon"
                            :icon="['fas', 'magnifying-glass']"
                            :class="{ rotated: showFilter }"
                          />
                        </div>
                      </div>
                      <input
                        v-if="showFilter"
                        ref="savedReportsUIFilter"
                        v-model="filterText"
                        class="filter-input"
                        placeholder="Filter..."
                      />
                    </div>
                  </th>

                  <th
                    v-for="(header, i) in headersWithoutFirst"
                    :key="i"
                    :ref="`${header.name}_${i + 1}`"
                    :class="['headers', { 'sticky-column sticky-column-header': header.isSticky }]"
                    @mouseover="(el) => handleMouseOver(el, header.name, `${header.name}_${i + 1}`)"
                    @mouseleave="handleMouseLeave"
                  >
                    <span>{{ header.name }}</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="filteredRows.length" class="body-half-screen">
                <tr
                  v-for="(item, i) in filteredRows"
                  :id="i"
                  :key="i"
                  :class="[{ 'sub-row': !!item.subRow }, { highlightBackgroundParent: item.open }]"
                >
                  <td
                    :ref="`row_name_${i}`"
                    class="saved-reports-cell"
                    @mouseover="handleMouseOver($event, item.name, `row_name_${i}`)"
                    @mouseleave="handleMouseLeave"
                  >
                    <div class="tooltip-container">{{ item.name }}</div>
                  </td>
                  <td
                    :ref="`row_dateRange_${i}`"
                    @mouseover="handleMouseOver($event, item.dateRange, `row_dateRange_${i}`)"
                    @mouseleave="handleMouseLeave"
                  >
                    <div class="tooltip-container">{{ item.dateRange }}</div>
                  </td>
                  <td
                    :ref="`row_createdAt_${i}`"
                    @mouseover="handleMouseOver($event, item.createdAt, `row_createdAt_${i}`)"
                    @mouseleave="handleMouseLeave"
                  >
                    <div class="tooltip-container">{{ item.createdAt }}</div>
                  </td>
                  <td>
                    <div class="action-icons">
                      <div
                        :id="'view-report_btn' + i"
                        :ref="`view_report_${i}`"
                        class="ftop-icons fltr-btn tt"
                        @click="viewReport(item)"
                        @mouseover="handleMouseOver($event, 'View Report', `view_report_${i}`)"
                        @mouseleave="handleMouseLeave"
                      >
                        <font-awesome-icon class="action-icon" :icon="['fas', 'eye']" />
                      </div>
                      <div
                        v-if="downloading[item.id]"
                        :id="'download-report_spinner' + i"
                        :ref="`downloading_report_${i}`"
                        class="ftop-icons fltr-btn tt"
                        @mouseover="
                          handleMouseOver(
                            $event,
                            'Downloading Report...',
                            `downloading_report_${i}`
                          )
                        "
                        @mouseleave="handleMouseLeave"
                      >
                        <font-awesome-icon
                          class="action-icon"
                          :icon="['fas', 'circle-notch']"
                          spin
                        />
                      </div>
                      <div
                        v-else
                        :id="'download-report_btn' + i"
                        :ref="`download_report_${i}`"
                        class="ftop-icons fltr-btn tt"
                        @click="downloadReport(item)"
                        @mouseover="
                          handleMouseOver($event, 'Download Report', `download_report_${i}`)
                        "
                        @mouseleave="handleMouseLeave"
                      >
                        <font-awesome-icon class="action-icon" :icon="['fas', 'arrow-to-bottom']" />
                      </div>
                      <div
                        v-if="emailing[item.id]"
                        :id="'emailing-report_spinner' + i"
                        :ref="`emailing_report_${i}`"
                        class="ftop-icons fltr-btn tt"
                        @mouseover="
                          handleMouseOver($event, 'Emailing Report...', `emailing_report_${i}`)
                        "
                        @mouseleave="handleMouseLeave"
                      >
                        <font-awesome-icon
                          class="action-icon"
                          :icon="['fas', 'circle-notch']"
                          spin
                        />
                      </div>
                      <div
                        v-else
                        :id="'emailing-report_btn' + i"
                        :ref="`email_report_${i}`"
                        class="ftop-icons fltr-btn tt"
                        @click="emailReport(item)"
                        @mouseover="handleMouseOver($event, 'Email Report', `email_report_${i}`)"
                        @mouseleave="handleMouseLeave"
                      >
                        <font-awesome-icon class="action-icon" :icon="['fas', 'envelope']" />
                      </div>
                      <div
                        :id="'delete-report_btn' + i"
                        :ref="`delete_report_${i}`"
                        class="ftop-icons fltr-btn tt"
                        @click="showDeleteConfirmationPopup(item)"
                        @mouseover="handleMouseOver($event, 'Delete Report', `delete_report_${i}`)"
                        @mouseleave="handleMouseLeave"
                      >
                        <font-awesome-icon class="action-icon" :icon="['fas', 'trash']" />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <div v-else class="txt-center" style="margin-top: 13px;">
                No data is available to show
              </div>
            </table>
          </template>
        </div>
      </div>
      <div :style="activeEl.style" class="tooltipText">{{ activeEl.text }}</div>

      <div
        id="myScheduledReportsContainer"
        ref="table-scroll-scheduled-report"
        class="table-scroll my-scheduled-reports-container"
      >
        <div>
          <template v-if="!loading">
            <table class="tbl tbl-export" :class="css">
              <thead class="header-top">
                <tr>
                  <th
                    v-if="rowsCollapsible"
                    class="short-column sticky-column-header subRowCollapsible"
                  ></th>

                  <th
                    :ref="`${scheduledReportHeaders[0].name}_0`"
                    :class="[
                      'headers',
                      { 'sticky-column sticky-column-header': scheduledReportHeaders[0].isSticky },
                    ]"
                    @mouseover="
                      (el) =>
                        handleMouseOver(
                          el,
                          scheduledReportHeaders[0].name,
                          `${scheduledReportHeaders[0].name}_0`
                        )
                    "
                    @mouseleave="handleMouseLeave"
                  >
                    <div class="header-container">
                      <div class="header-row">
                        <span class="header-text">{{ scheduledReportHeaders[0].name }}</span>
                        <div @click="toggleSchedFilter">
                          <font-awesome-icon
                            class="filter-icon"
                            :icon="['fas', 'magnifying-glass']"
                            :class="{ rotated: showSchedFilter }"
                          />
                        </div>
                      </div>
                      <input
                        v-if="showSchedFilter"
                        ref="schedReportsUIFilter"
                        v-model="filterSchedText"
                        class="filter-input"
                        placeholder="Filter..."
                      />
                    </div>
                  </th>

                  <th
                    v-for="(header, i) in scheduledReportHeadersWithoutFirst"
                    :id="
                      'scheduled-reports-header-' + i + 1 + '-' + header.name.replace(/\s+/g, '')
                    "
                    :key="i"
                    :ref="`${header.name}_${i + 1}_sched`"
                    :class="['headers', { 'sticky-column sticky-column-header': header.isSticky }]"
                    @mouseover="
                      (el) =>
                        handleMouseOverScheduled(el, header.name, `${header.name}_${i + 1}_sched`)
                    "
                    @mouseleave="handleMouseLeaveScheduled"
                  >
                    <span>{{ header.name }}</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="filteredScheduledReports.length" class="body-half-screen">
                <tr
                  v-for="(item, i) in filteredScheduledReports"
                  :id="i"
                  :key="i"
                  :class="[{ 'sub-row': !!item.subRow }, { highlightBackgroundParent: item.open }]"
                >
                  <td
                    :ref="`row_name_${i}_sched`"
                    class="saved-reports-cell"
                    @mouseover="handleMouseOverScheduled($event, item.name, `row_name_${i}_sched`)"
                    @mouseleave="handleMouseLeaveScheduled"
                  >
                    <div class="tooltip-container">{{ item.name }}</div>
                  </td>
                  <td
                    :ref="`row_schedule_${i}_sched`"
                    @mouseover="
                      handleMouseOverScheduled($event, item.schedule, `row_schedule_${i}_sched`)
                    "
                    @mouseleave="handleMouseLeaveScheduled"
                  >
                    <div class="tooltip-container">{{ item.schedule }}</div>
                  </td>
                  <td
                    v-if="toggleLoading[item.id]"
                    :id="'active-status-spinner' + i"
                    :ref="`row_scheduleStatus_${i}_sched_loading`"
                    @mouseover="
                      handleMouseOverScheduled(
                        $event,
                        'Changing status...',
                        `row_scheduleStatus_${i}_sched_loading`
                      )
                    "
                    @mouseleave="handleMouseLeaveScheduled"
                  >
                    <font-awesome-icon
                      class="action-icon"
                      style="font-size: 25px;margin-left: 8px;"
                      :icon="['fas', 'circle-notch']"
                      spin
                    />
                  </td>
                  <td
                    v-else
                    :id="'active-status_toggle' + i"
                    :ref="`row_scheduleStatus_${i}_sched`"
                    @mouseover="
                      handleMouseOverScheduled(
                        $event,
                        item.scheduleStatus,
                        `row_scheduleStatus_${i}_sched`
                      )
                    "
                    @mouseleave="handleMouseLeaveScheduled"
                  >
                    <ToggleSwitch
                      :is-active="toggleSwitchStatus(item.scheduleStatus)"
                      @update:isActive="handleToggle(item)"
                    />
                  </td>
                  <td
                    :ref="`row_status_${i}_sched`"
                    class="saved-reports-cell"
                    @click="showMoreStatus(item)"
                    @mouseover="
                      handleMouseOverScheduled(
                        $event,
                        getStatusTooltip(item),
                        `row_status_${i}_sched`
                      )
                    "
                    @mouseleave="handleMouseLeaveScheduled"
                  >
                    <font-awesome-icon
                      class="action-icon"
                      style="font-size: 24px;margin-left: 8px;"
                      :style="{
                        color: getStatusColor(item),
                      }"
                      :icon="getStatusIcon(item)"
                    />
                  </td>
                  <td>
                    <div class="action-icons">
                      <div
                        v-if="savingEmail[item.id]"
                        :id="'saving-email_spinner' + i"
                        :ref="`saving_email_${i}`"
                        class="ftop-icons fltr-btn tt"
                        @mouseover="
                          handleMouseOverScheduled($event, 'Saving Emails...', `saving_email_${i}`)
                        "
                        @mouseleave="handleMouseLeaveScheduled"
                      >
                        <font-awesome-icon
                          class="action-icon"
                          :icon="['fas', 'circle-notch']"
                          spin
                        />
                      </div>
                      <div
                        v-else
                        :id="'edit-email_btn' + i"
                        :ref="`edit_mail_list_${i}_sched`"
                        class="ftop-icons fltr-btn tt"
                        @click="editEmailList(item)"
                        @mouseover="
                          handleMouseOverScheduled(
                            $event,
                            'Edit Email Recipients',
                            `edit_mail_list_${i}_sched`
                          )
                        "
                        @mouseleave="handleMouseLeaveScheduled"
                      >
                        <font-awesome-icon class="action-icon" :icon="['fas', 'pencil-alt']" />
                      </div>

                      <div
                        :id="'delete-sched-report_btn' + i"
                        :ref="`delete_${i}_sched`"
                        class="ftop-icons fltr-btn tt"
                        @click="showDeleteScheduledReportPopup(item)"
                        @mouseover="
                          handleMouseOverScheduled(
                            $event,
                            'Delete Scheduled Report',
                            `delete_${i}_sched`
                          )
                        "
                        @mouseleave="handleMouseLeaveScheduled"
                      >
                        <font-awesome-icon class="action-icon" :icon="['fas', 'trash']" />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <div v-else class="txt-center" style="margin-top: 13px;">
                No data is available to show
              </div>
            </table>
          </template>
        </div>
      </div>
      <div :style="activeElScheduled.style" class="tooltipTextSched">
        {{ activeElScheduled.text }}
      </div>
    </div>

    <b-loading-spinner v-if="loading" class="txt-center-full" />

    <b-email-btn
      ref="emailComponent"
      :show-on-my-reports-section="true"
      :show-fill-with-user-email="true"
      :email-status="emailSentStatus"
      :external-open="openEmailPopup"
      :show-on-saved-reports="true"
      @email-to="sendEmailReport"
    />

    <div v-if="showDeleteTemplatePopup.visible" class="show-delete-template-popup">
      <SavedReportConfirmModal
        :description="showDeleteTemplatePopup.description"
        :loading="isDeleteLoading"
        @cancel="onCloseDeletePopup"
        @change="onDeleteTemplatePopup"
      />
    </div>

    <b-email-recipients-btn
      ref="emailRecipientComponent"
      :emails="emailTo"
      @save-email-recipients="saveEmailRecipients"
    />

    <b-report-run-status ref="runStatusComponent" />
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import config from '@/config';
import axios from 'axios';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import fileDownload from 'js-file-download';
import moment from 'moment';
import advertiserReportsApi from '@/api/advertiser-reports';
import { formatDateForAPI } from '@/util/apiDateFormat';
import { buildQueryString } from '@/helpers/global/url-helpers';
import { ACCOUNTS_TO_SHOW, DEFAULT_METHODOLOGY, MESSAGES, PRE_DEFINED_TEMPLATES } from '@/constant';
import ToggleSwitch from '@/components/elements/b-toggle-switch.vue';
import { isBlank } from 'adready-api/helpers/common';
import SavedReportConfirmModal from './saved-report-confirm-modal.vue';
import BEmailRecipientsBtn from './b-email-recipients-btn.vue';
import BReportRunStatus from './b-report-run-status.vue';

const headers = [
  { name: 'Saved Reports' },
  { name: 'Date Range' },
  { name: 'Created' },
  { name: 'Actions' },
];

const scheduledReportHeaders = [
  { name: 'Scheduled Reports' },
  { name: 'Scheduled' },
  { name: 'Active' },
  { name: 'Status' },
  { name: 'Actions' },
];

export default {
  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BEmailBtn: () =>
      import(/* webpackChunkName: "b-email-btn" */ '~/components/elements/b-email-btn.vue'),
    BEmailRecipientsBtn,
    BReportRunStatus,
    SavedReportConfirmModal,
    ToggleSwitch,
  },
  props: {
    rows: { type: Array, default: () => [] },
    scheduledReports: { type: Array, default: () => [] },
    headers: { type: Array, default: () => headers },
    scheduledReportHeaders: { type: Array, default: () => scheduledReportHeaders },
    rowToScrollTo: { type: String, default: '' },
    rowsCollapsible: { type: Boolean, default: false },
    css: { type: String, default: '' },
    isPageView: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    error: { type: String, default: '' },
  },
  data() {
    return {
      activeEl: { style: { display: 'none' }, text: '' },
      activeElScheduled: { style: { display: 'none' }, text: '' },
      showMessage: false,
      message: '',
      messageType: 'success',
      showDeleteTemplatePopup: { visible: false, payload: {} },
      isDeleteLoading: false,
      downloading: {},
      emailing: {},
      emailSentStatus: '',
      openEmailPopup: false,
      toEmail: {},
      toggleLoading: {},
      emailTo: [],
      scheduleItem: {},
      savingEmail: {},

      showFilter: false,
      filterText: '',
      showSchedFilter: false,
      filterSchedText: '',
    };
  },
  computed: {
    incReachMetricsData: get('dashboard/incReachMetricsData'),
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    universalPixelId: get('common/universalPixelId'),
    attribution: get('dashboard/filters@attribution'),
    eventOptions: get('dashboard/filters@eventOptions'),
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
    selectedEventLabel() {
      return isBlank(this.selectedEvent.eventLabel) ? 'Event' : this.selectedEvent.eventLabel;
    },
    headersWithoutFirst() {
      return [...this.headers].splice(1);
    },
    filteredRows() {
      if (!this.filterText) {
        return this.rows;
      }
      return this.rows.filter((row) =>
        row.name.toLowerCase().includes(this.filterText.toLowerCase())
      );
    },
    scheduledReportHeadersWithoutFirst() {
      return [...this.scheduledReportHeaders].splice(1);
    },
    filteredScheduledReports() {
      if (!this.filterSchedText) {
        return this.scheduledReports;
      }
      return this.scheduledReports.filter((row) =>
        row.name.toLowerCase().includes(this.filterSchedText.toLowerCase())
      );
    },
  },
  watch: {
    error(newValue) {
      if (newValue) {
        this.showErrorMessage(newValue);
      }
    },
  },
  beforeDestroy() {
    this.toEmail = {};
  },
  mounted() {
    this.scrollToRow(this.rowToScrollTo);
    this.toEmail = {};
  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
      if (this.showFilter) {
        this.$nextTick(() => {
          this.$refs.savedReportsUIFilter.focus();
        });
      }
      if (!this.showFilter) {
        this.filterText = ''; // Clear filter when closing
      }
    },
    toggleSchedFilter() {
      this.showSchedFilter = !this.showSchedFilter;
      if (this.showSchedFilter) {
        this.$nextTick(() => {
          this.$refs.schedReportsUIFilter.focus();
        });
      }
      if (!this.showSchedFilter) {
        this.filterSchedText = ''; // Clear filter when closing
      }
    },
    getStatusTooltip(item) {
      if (item.lastRunDate && item.lastRunStatus) {
        if (item.lastRunStatus.toLowerCase() === 'success') {
          return `${'Last Run Status: Successful\nLast Run Date: '}${
            item.lastRunDate
          }\n\nClick to show Run History`;
        }
        return `${'Last Run Status: Failed\nLast Run Date: '}${
          item.lastRunDate
        }\n\nClick to show Run History`;
      }
      return 'Pending';
    },
    getStatusColor(item) {
      if (item.lastRunDate && item.lastRunStatus) {
        if (item.lastRunStatus.toLowerCase() === 'success') {
          return '#00ff00'; // Green
        }
        return '#ff0000'; // Red
      }
      return '#aaaaaa'; // Default gray
    },
    getStatusIcon(item) {
      if (item.lastRunDate && item.lastRunStatus) {
        if (item.lastRunStatus.toLowerCase() === 'success') {
          return ['far', 'check-circle'];
        }
        return ['far', 'times-circle'];
      }
      return ['far', 'circle-dashed'];
    },
    handleMouseOver(el, text, refKey, isRow = false) {
      const elementPos = this.$refs[refKey][0];
      const tableElement = this.$refs['table-scroll'];
      const addScrollTop = isRow && !this.isPageView ? 170 : 35;

      if (!elementPos) return;

      this.activeEl = {
        style: {
          top: `${elementPos.offsetParent.offsetTop - tableElement.scrollTop + addScrollTop}px`,
          left: `${elementPos.offsetLeft - tableElement.scrollLeft + 40}px`,
          display: 'block',
        },
        text,
      };
    },
    handleMouseLeave() {
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    handleMouseOverScheduled(el, text, refKey, isRow = false) {
      const elementPos = this.$refs[refKey][0];
      const tableElement = this.$refs['table-scroll-scheduled-report'];
      const addScrollTop = isRow && !this.isPageView ? 170 : 35;

      if (!elementPos) return;

      this.activeElScheduled = {
        style: {
          top: `${elementPos.offsetParent.offsetTop - tableElement.scrollTop + addScrollTop}px`,
          left: `${elementPos.offsetLeft - tableElement.scrollLeft + 640}px`,
          display: 'block',
        },
        text,
      };
    },
    handleMouseLeaveScheduled() {
      this.activeElScheduled = { style: { display: 'none' }, text: '' };
    },
    scrollToRow(rowId) {
      const row = this.$refs['table-scroll']?.querySelector(`table tbody tr[id="${rowId}"]`);
      if (row) this.$refs['table-scroll']?.scrollTo(0, row.offsetTop - row.offsetHeight - 5);
    },
    genCSVFileName(item) {
      return `${item.name}-${moment(new Date()).format('yyyy-MM-DD')}.csv`;
    },
    saveCSV(data, fileName = 'file.csv') {
      fileDownload(data, fileName);
    },
    emailReport(item) {
      this.toEmail = item;
      this.$refs.emailComponent.openCloseEmailPopup();
    },
    async triggerTouchReportEmail(payload, email) {
      this.applyTemplateFilters(payload.filters);
      const payLoad = this.preparePayload(payload);
      delete payLoad.interval;
      delete payLoad.showUniques;
      delete payLoad.attribution;
      delete payLoad.methodology;
      payLoad.kind =
        payload?.filters?.template?.toLowerCase() ||
        payload?.filters?.dimensions[0]?.toLowerCase() ||
        '';
      payLoad.includeChannel = !!ACCOUNTS_TO_SHOW.includes(this.account?.id);
      if (this.selectedEvent.allSubCategoriesSelected) {
        delete payLoad.subCategory;
      }
      try {
        const filters = {
          ...payLoad,
          emailAddr: email,
          onceNow: true,
          emailIt: true,
        };
        const opts = {
          headers: {
            Accept: 'application/json',
          },
          respondHeaders: true,
        };
        await advertiserReportsApi.reportsRollup(
          this.advertiser.id,
          buildQueryString(filters),
          opts
        );
        this.emailSentStatus = 'success';
        this.$set(this.emailing, this.toEmail.id, false);
        this.showSuccessMessage(`Report has been sent to the email id: ${email} successfully.`);
      } catch (err) {
        const errorMessage =
          err.response?.message ||
          'Due to internal error, email has not been sent now. Please try later!';
        console.error('error sending email ->', err);
        this.emailSentStatus = 'error';
        this.showErrorMessage(errorMessage);
        this.$set(this.emailing, this.toEmail.id, false);
        throw err;
      } finally {
        setTimeout(() => {
          this.emailSentStatus = '';
          this.$set(this.emailing, this.toEmail.id, false);
          this.openEmailPopup = false;
        }, 1000);
      }
    },
    showMoreStatus(item) {
      this.$refs.runStatusComponent.openClosePopup(item);
    },
    async triggerEpEmail(payload, email) {
      const payLoad = this.preparePayload(payload);
      const selectedReportKind =
        payload?.filters?.template?.toLowerCase() ||
        payload?.filters?.dimensions[0]?.toLowerCase() ||
        '';

      try {
        const filters = {
          ...payLoad,
          event: 'Leads',
          category: 'lead',
          kind: selectedReportKind,
          emailAddr: email,
          onceNow: true,
          emailIt: true,
        };
        const opts = {
          headers: {
            Accept: 'application/json',
          },
          respondHeaders: true,
        };
        await advertiserReportsApi.reportsEventPerformance(
          this.advertiser.id,
          buildQueryString(filters),
          opts
        );

        this.emailSentStatus = 'success';
        this.$set(this.emailing, this.toEmail.id, false);
        this.showSuccessMessage(MESSAGES.REPORT_EMAIL_SUCCESS(email));
      } catch (err) {
        const errorMessage =
          err.response?.message ||
          'Due to internal error, email has not been sent now. Please try later!';
        console.error('error sending email ->', err);
        this.emailSentStatus = 'error';
        this.showErrorMessage(errorMessage);
        this.$set(this.emailing, this.toEmail.id, false);
        throw err;
      } finally {
        setTimeout(() => {
          this.emailSentStatus = '';
          this.$set(this.emailing, this.toEmail.id, false);
          this.openEmailPopup = false;
        }, 1000);
      }
    },
    sendEmailReport(email = '') {
      setTimeout(() => {
        this.openEmailPopup = false;
        this.$refs.emailComponent.openCloseEmailPopup();
      }, 500);

      this.$set(this.emailing, this.toEmail.id, true);
      if (this.toEmail.filters.reportMetrics.some((metric) => metric?.metrics === 'ep')) {
        this.triggerEpEmail(this.toEmail, email);
        return;
      }
      if (
        this.toEmail.filters.dimensions.length === 1 &&
        PRE_DEFINED_TEMPLATES.includes(this.toEmail.filters.dimensions[0])
      ) {
        this.triggerTouchReportEmail(this.toEmail, email);
        return;
      }
      const payLoad = {
        id: this.toEmail.id,
        advertiserId: this.toEmail.advertiserId,
      };
      const payload = { ...payLoad, emailTo: email, onceNow: true, emailIt: true, email: true };
      const url = `${config.ADREADY_URL}/api/ac/advertisers/${payload.advertiserId}/reportTemplate/run`;
      delete payload.offset;
      delete payload.limit;
      axios
        .post(url, payload, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          respondHeaders: true,
        })
        .then((response) => {
          if (response?.status === 200) {
            this.emailSentStatus = 'success';
            this.showSuccessMessage(MESSAGES.REPORT_EMAIL_SUCCESS(email));
            this.$set(this.emailing, this.toEmail.id, false);
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response?.message ||
            'Due to internal error, email has not been sent now. Please try later!';
          console.error('Error:', errorMessage);
          this.showErrorMessage(errorMessage);
          this.emailSentStatus = 'error';
          this.$set(this.emailing, this.toEmail.id, false);
        })
        .finally(() => {
          setTimeout(() => {
            this.emailSentStatus = '';
            this.$set(this.emailing, this.toEmail.id, false);
            this.openEmailPopup = false;
          }, 1000);
        });
    },
    preparePayload(payload) {
      return {
        advertiser: this.advertiser?.name || '',
        client: this.account?.name || '',
        xandrId: this.advertiser?.xandrAdvertiserId || '',
        pixel: this.universalPixelId,
        mediaType: payload?.filters?.mediaTypes,
        ioIds: payload?.filters?.campaignIds,
        startDate: formatDateForAPI(payload?.filters?.startDate),
        endDate: formatDateForAPI(payload?.filters?.endDate),
        adGroups: payload?.filters?.adGroups ?? '',
        audience: payload?.filters?.audiences,
        publisher: payload?.filters?.publisher,
        creative: payload?.filters?.creatives,
        conversionWindow:
          payload?.filters?.conversionWindowUnit === 'DAYS'
            ? payload?.filters?.conversionWindow * 24
            : payload?.filters?.conversionWindow || 744,
        showUniques: payload?.filters?.showUniques,
        methodology: payload?.filters?.methodology?.toLowerCase() || DEFAULT_METHODOLOGY,
        attribution: this.attribution,
        event: this.selectedEventLabel,
        category: isBlank(this.selectedEvent.category) ? 'none' : this.selectedEvent.category,
        subCategory: this.selectedEvent.subCategories,
        interval: payload?.filters?.interval || 'CUMULATIVE',
        reportBuilderV2: true,
        templateName: payload?.name ?? '',
        dateRangeType: payload?.filters?.dateRangeType ?? '',
      };
    },
    async triggerTouchReportsDownload(payload) {
      this.applyTemplateFilters(payload.filters);
      const payLoad = this.preparePayload(payload);
      delete payLoad.interval;
      delete payLoad.showUniques;
      delete payLoad.attribution;
      delete payLoad.methodology;
      payLoad.kind =
        payload?.filters?.template?.toLowerCase() ||
        payload?.filters?.dimensions[0]?.toLowerCase() ||
        '';
      payLoad.includeChannel = !!ACCOUNTS_TO_SHOW.includes(this.account?.id);
      if (this.selectedEvent.allSubCategoriesSelected) {
        delete payLoad.subCategory;
      }
      const opts = {
        headers: {
          Accept: 'application/json',
        },
        respondHeaders: true,
      };
      opts.headers.Accept = 'text/csv';
      opts.responseType = 'blob';
      try {
        const res = await advertiserReportsApi.reportsRollup(
          this.advertiser.id,
          buildQueryString(payLoad),
          opts
        );
        this.saveCSV(res.data, this.genCSVFileName(payload));
        this.$set(this.downloading, payload.id, false);
      } catch (err) {
        console.error('error downloading file -> ', err);
        this.$set(this.downloading, payload.id, false);
        throw err;
      } finally {
        this.$set(this.downloading, payload.id, false);
      }
    },
    async triggerEpDownload(payload) {
      let payLoad = this.preparePayload(payload);
      const selectedReportKind =
        payload?.filters?.template?.toLowerCase() ||
        payload?.filters?.dimensions[0]?.toLowerCase() ||
        '';

      payLoad = {
        ...payLoad,
        kind: selectedReportKind,
        fields: ['name', 'ep'],
        event: 'Leads',
        category: 'lead',
      };
      try {
        const opts = {
          headers: {
            Accept: 'text/csv',
          },
          respondHeaders: true,
          responseType: 'blob',
        };
        const res = await advertiserReportsApi.reportsEventPerformance(
          this.advertiser.id,
          buildQueryString(payLoad),
          opts
        );
        this.saveCSV(res.data, this.genCSVFileName(payload));
        this.$set(this.downloading, payload.id, false);
      } catch (err) {
        console.error('error downloading file -> ', err);
        this.$set(this.downloading, payload.id, false);
        throw err;
      } finally {
        this.$set(this.downloading, payload.id, false);
      }
    },
    downloadReport(item) {
      this.$set(this.downloading, item.id, true);
      if (item.filters.reportMetrics.some((metric) => metric?.metrics === 'ep')) {
        this.triggerEpDownload(item);
        return;
      }
      if (
        item.filters.dimensions.length === 1 &&
        PRE_DEFINED_TEMPLATES.includes(item.filters.dimensions[0])
      ) {
        this.triggerTouchReportsDownload(item);
        return;
      }
      const payload = {
        id: item.id,
        export: true,
      };
      const url = `${config.ADREADY_URL}/api/ac/advertisers/${item.advertiserId}/reportTemplate/run`;
      axios
        .post(url, payload, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/csv',
          },
          responseType: 'blob',
        })
        .then((response) => {
          if (response?.status === 200) {
            this.saveCSV(response.data, this.genCSVFileName(item));
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || 'An unknown error occurred';
          console.error('Error:', errorMessage);
          this.$set(this.downloading, item.id, false);
          this.showErrorMessage(errorMessage);
        })
        .finally(() => {
          this.$set(this.downloading, item.id, false);
        });
    },
    triggerEpSearch(payload) {
      this.$nextTick(() => {
        if (EventBus.listenerReady) {
          EventBus.$emit('do-new-report-search', payload);
        } else {
          console.log('Listener not ready, will retry...');
          setTimeout(() => this.triggerEpSearch(payload), 100);
        }
      });
    },
    triggerTouchReportsSearch(payload) {
      this.$nextTick(() => {
        if (EventBus.listenerReady) {
          // If the listener is ready, emit the event
          EventBus.$emit('do-new-report-search', payload);
        } else {
          // If the listener isn't ready, retry after a short delay
          setTimeout(this.triggerSearch(payload), 100); // Retry after 100ms
        }
      });
    },
    viewReport(item) {
      if (item.filters.reportMetrics.some((metric) => metric?.metrics === 'ep')) {
        EventBus.$emit('switch-to-tab-index', 2, item.name);
        this.triggerEpSearch(item);
        return;
      }
      if (
        item.filters.dimensions.length === 1 &&
        PRE_DEFINED_TEMPLATES.includes(item.filters.dimensions[0])
      ) {
        EventBus.$emit('switch-to-tab-index', 2, item.name);
        this.applyTemplateFilters(item.filters);
        this.triggerTouchReportsSearch(item);
        return;
      }
      const payload = {
        id: item.id,
        advertiserId: item.advertiserId,
        name: item.name,
        filters: item.filters,
      };
      EventBus.$emit('switch-to-tab-index', 2, item.name);
      this.triggerSearch(payload);
    },
    applyTemplateFilters(templateFilters) {
      this.eventOptions.forEach((event) => {
        const matchedEvent = templateFilters.reportMetrics.find((item) => {
          const formattedMetric = this.formatEventNamesReverse(item.metrics);
          if (
            (item.metrics === 'completions' || item.metrics === 'clicks') &&
            formattedMetric === event.event
          ) {
            // Allow item.event to be false for these specific cases
            return true;
          }
          // Default logic for other cases
          return formattedMetric === event.event && item.event === true;
        });
        if (matchedEvent) {
          event.selected = true;
          if (event.children && event.children.length > 0) {
            event.children.forEach((subCategory) => {
              const matchedSubCategory = matchedEvent.subCategory.find(
                (sub) => sub === subCategory.category
              );
              subCategory.selected = !!matchedSubCategory;
            });
          }
        } else {
          event.selected = false;
          if (event.children && event.children.length > 0) {
            event.children.forEach((subCategory) => {
              subCategory.selected = false;
            });
          }
        }
      });
    },
    formatEventNamesReverse(name) {
      const eventMap = {
        lead: 'Leads',
        checkout: 'Checkouts',
        revenue: 'Revenue',
        pageVisit: 'Page Visits',
        completions: 'Video Completions',
        clicks: 'Clicks',
        locationVisit: 'Location Visits',
        activity: 'Activities',
      };
      return eventMap[name] || name;
    },
    triggerSearch(payload) {
      if (EventBus.myReportsListenerReady) {
        EventBus.$emit('do-new-report-search-from-my-reports', payload);
      } else {
        setTimeout(() => this.triggerSearch(payload), 200);
      }
    },
    async deleteReport(item) {
      const url = `${config.ADREADY_URL}/api/ac/advertisers/${item.advertiserId}/reportTemplate/${item.id}`;
      try {
        const response = await axios.delete(url, { withCredentials: true });
        if (response.data.status === 200) {
          // setting to true to fetch updated report list
          EventBus.fetchSavedReports = true;

          this.showSuccessMessage(`${item.name} has been deleted.`);

          const index = this.rows.indexOf(item);
          if (index > -1) {
            this.rows.splice(index, 1);
          }

          const indexSch = this.scheduledReports.indexOf(item);
          if (indexSch > -1) {
            this.scheduledReports.splice(indexSch, 1);
          }
        }
      } catch (error) {
        console.error(`error processing report ->`, error);
        this.showErrorMessage(
          `Encountered an error deleting ${item.name}. Please contact administrator.`
        );
      } finally {
        this.$nextTick(() => {
          this.isDeleteLoading = false;
          this.onCloseDeletePopup();
        });
      }
    },
    showSuccessMessage(msg) {
      this.message = msg;
      this.messageType = 'success';
      this.displayMessage();
    },
    showErrorMessage(msg) {
      this.message = msg;
      this.messageType = 'error';
      this.displayMessage();
    },
    displayMessage() {
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 5000);
    },
    showDeleteConfirmationPopup(item) {
      this.showDeleteTemplatePopup = {
        visible: true,
        description: `Delete report ${item.name}?`,
        payload: {
          item,
        },
      };
    },
    showDeleteScheduledReportPopup(item) {
      this.showDeleteTemplatePopup = {
        visible: true,
        description: `Delete scheduled report ${item.name}?`,
        payload: {
          item,
        },
      };
    },
    onCloseDeletePopup() {
      this.showDeleteTemplatePopup = { visible: false, payload: {} };
    },
    onDeleteTemplatePopup() {
      this.isDeleteLoading = true;
      this.$nextTick(() => {
        const { payload } = this.showDeleteTemplatePopup;
        this.deleteReport(payload.item);
      });
    },
    toggleSwitchStatus(status) {
      return status === 'ACTIVE';
    },
    handleToggle(item) {
      this.$set(this.toggleLoading, item.id, true);
      this.$emit('update-data-loading', true);

      const prevStatus = item.scheduleStatus;

      delete item.createdAt;
      delete item.createdBy;
      delete item.updatedAt;
      delete item.updatedBy;
      item.scheduleStatus = item.scheduleStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';

      const url = `${config.ADREADY_URL}/api/ac/advertisers/${this.advertiser?.id}/reportTemplate/${item.id}`;
      const apiCall = axios.put(url, item, {
        withCredentials: true,
      });

      // setting to true to fetch updated report list
      EventBus.fetchSavedReports = true;

      apiCall
        .then((response) => {
          if (response?.data?.status === 200) {
            this.$set(this.toggleLoading, item.id, false);
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || 'An unknown error occurred';
          item.scheduleStatus = prevStatus;
          this.showErrorMessage(errorMessage);
        })
        .finally(() => {
          this.$set(this.toggleLoading, item.id, false);
          this.$emit('update-data-loading', false);
        });
    },
    editEmailList(item) {
      this.$nextTick(() => {
        this.emailTo = item.emailTo.split(',').map((email) => email?.trim());
        this.scheduleItem = item;
        this.$refs.emailRecipientComponent.openClosePopup();
      });
    },
    saveEmailRecipients() {
      setTimeout(() => {
        this.$refs.emailRecipientComponent.openClosePopup();
      });

      this.$set(this.savingEmail, this.scheduleItem.id, true);

      this.$emit('update-data-loading', true);

      // check if there is change
      const emailArray = this.scheduleItem.emailTo.split(',').map((email) => email?.trim());
      const cleanEmailArray = Array.isArray(this.emailTo)
        ? this.emailTo.map((email) => email?.trim())
        : [];

      const isEqual = JSON.stringify(emailArray) === JSON.stringify(cleanEmailArray);

      if (isEqual) {
        this.$set(this.savingEmail, this.scheduleItem.id, false);
        return;
      }

      const payload = this.scheduleItem;

      delete payload.createdAt;
      delete payload.createdBy;
      delete payload.updatedAt;
      delete payload.updatedBy;
      payload.emailTo = cleanEmailArray
        .map((email) => email?.trim()) // Trim spaces from each email
        .join(',');

      const url = `${config.ADREADY_URL}/api/ac/advertisers/${this.advertiser?.id}/reportTemplate/${payload.id}`;
      const apiCall = axios.put(url, payload, {
        withCredentials: true,
      });

      // setting to true to fetch updated report list
      EventBus.fetchSavedReports = true;

      apiCall
        .then((response) => {
          if (response?.data?.status === 200) {
            this.showSuccessMessage(
              `Successfully updated Email Recipients for ${this.scheduleItem.name}`
            );
            this.$set(this.savingEmail, this.scheduleItem.id, false);
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || 'An unknown error occurred';
          payload.emailTo = emailArray
            .map((email) => email?.trim()) // Trim spaces from each email
            .join(',');
          this.showErrorMessage(errorMessage);
        })
        .finally(() => {
          this.$set(this.savingEmail, this.scheduleItem.id, false);
          this.$emit('update-data-loading', false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen {
  * {
    box-sizing: border-box;
  }
  .table-wrap {
    .tbl {
      border-collapse: separate;
      thead {
        z-index: 4;
        background-color: var(--primarycolor) !important;
      }
      tr td {
        word-wrap: break-word;
        white-space: normal;
        overflow-wrap: break-word;
      }
    }
    .table-scroll {
      width: 50%;
      max-height: 36.5rem !important;
      overflow: auto;
    }
    .message {
      white-space: pre-line;
    }
    .message-container {
      margin-bottom: 10px;
      padding: 10px;
      text-align: center;
      border-radius: 4px;
    }
    .message.success {
      background-color: #d4edda;
      color: #155724;
      border: 1px solid #c3e6cb;
    }
    .message.error {
      background-color: #f8d7da;
      color: #721c24;
      border: 1px solid #f5c6cb;
    }
  }
  table.tbl-export {
    & thead {
      padding-bottom: unset;
      border-bottom: unset;
    }
    & td.short-column.sticky-column.subRowCollapsible {
      width: 26px !important;
      overflow: unset;
    }
    & th.short-column.sticky-column-header.subRowCollapsible {
      width: 26px !important;
    }
    .sticky-column {
      z-index: 1;
      background-color: #212 429;
      &.subRowCollapsible {
        left: 0px;
      }
      &.sub-row-column-highlight {
        background-color: #191919;
      }
      &-header {
        background: var(--primarycolor);
        z-index: 5;
        &.subRowCollapsible {
          left: 0px;
        }
      }
    }
  }
  .body-half-screen {
    .relative {
      position: relative;
    }
    tr {
      position: relative;
    }
    tr td.highlightBorder {
      border-right: 2px solid var(--primarycolor);
      left: 1px;
      z-index: 3;
      height: 3em;
    }
  }
  .tooltipText {
    position: absolute;
    z-index: 999;
    width: auto;
    font-size: 12px;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
    text-align: center;
    border-radius: 3px;
    transition: ease 0.3s;
    line-height: 1.2em;
    transform-origin: bottom center;
    padding: 5px;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .tooltipTextSched {
    position: absolute;
    z-index: 999;
    width: auto;
    font-size: 12px;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
    text-align: left;
    border-radius: 3px;
    transition: ease 0.3s;
    line-height: 1.2em;
    transform-origin: bottom center;
    padding: 5px;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    white-space: pre-line;
  }
  .tooltipTextSched {
    position: absolute;
    z-index: 999;
    width: auto;
    font-size: 12px;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
    text-align: left;
    border-radius: 3px;
    transition: ease 0.3s;
    line-height: 1.2em;
    transform-origin: bottom center;
    padding: 5px;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    white-space: pre-line;
  }
  .highlightBackgroundParent {
    background-color: #191919;
    border-bottom: none;
    > .sticky-column {
      background-color: #191919;
      &:nth-child(n + 2):nth-child(-n + 5) {
        background-color: #191919;
        &:hover {
          background-color: #373a44;
        }
      }
      &:hover {
        background-color: #373a44;
      }
    }
    &:hover {
      background-color: #373a44;
    }
  }
  .my-saved-reports-container {
    td {
      word-break: break-all;
    }
    .action-icon {
      margin-right: 10px;
    }
    .headers {
      color: white;
      vertical-align: top !important;
    }
    th {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 7%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 13%;
      }
    }
    tr td {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 7%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 13%;
      }
    }
    .action-icon {
      cursor: pointer;
    }
  }
  .my-scheduled-reports-container {
    td {
      word-break: break-all;
    }
    .action-icon {
      margin-right: 10px;
    }
    .headers {
      color: white;
      vertical-align: top !important;
    }
    th {
      &:nth-child(1) {
        width: 27%;
      }
      &:nth-child(2) {
        width: 7%;
      }
      &:nth-child(3) {
        width: 7%;
      }
      &:nth-child(4) {
        width: 9%;
      }
    }
    tr td {
      &:nth-child(1) {
        width: 27%;
      }
      &:nth-child(2) {
        width: 7%;
      }
      &:nth-child(3) {
        width: 7%;
      }
      &:nth-child(4) {
        width: 9%;
      }
    }
    .action-icon {
      cursor: pointer;
    }
  }
  .my-scheduled-reports-container {
    td {
      word-break: break-all;
    }
    .action-icon {
      margin-right: 10px;
    }
    .headers {
      color: white;
      vertical-align: top !important;
    }
    th {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 7%;
      }
      &:nth-child(3) {
        width: 7%;
      }
      &:nth-child(4) {
        width: 7%;
      }
      &:nth-child(5) {
        width: 9%;
      }
    }
    tr td {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 7%;
      }
      &:nth-child(3) {
        width: 7%;
      }
      &:nth-child(4) {
        width: 7%;
      }
      &:nth-child(5) {
        width: 9%;
      }
    }
  }
  .txt-center {
    text-align: center;
    width: 50%;
  }
  .txt-center-full {
    text-align: center;
    width: 100%;
  }
  .ftop-icons {
    position: relative;
    display: inline-block;
    vertical-align: top;
    color: var(--primarydark2);
    cursor: pointer;
    font-size: 16px;
    margin-right: 3px;
    margin-left: 4px;

    .ttip-wrap {
      position: absolute;
      width: max-content;
      text-align: center;
      transform: translate3d(0px, 0px, 0px);
      padding-top: 0px;
      display: inline-block;
      visibility: hidden;
      opacity: 0;
      font-size: 14px;
      transition: all 0.2s ease;
      z-index: 103;
      pointer-events: none;
      left: -47px;
      top: 20px;

      .ttip {
        display: inline-block;
        position: relative;
        padding: 4px 8px;
        border-radius: 6px;
        background-color: var(--primarydark2);
        color: #fff;
        text-align: center;
        font-size: 0.8em;
        font-weight: 500;
        line-height: 1.1em;
        margin-top: 0px;
      }
      .ttip::before {
        content: '';
        display: inline-block;
        left: 50%;
        top: -5px;
        height: 0px;
        width: 0px;
        border-right: solid 6px transparent;
        border-left: solid 6px transparent;
        border-bottom: solid 6px var(--primarydark2);
        position: absolute;
        margin-left: -3px;
      }
    }
    &:hover .fa-filter {
      color: var(--primarycolor);
    }
    &:hover .ttip-wrap {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0px, 6px, 0px);
    }
  }
}
.saved-reports-cell {
  word-wrap: break-word; /* Allow long words to be broken and wrap onto the next line */
  overflow: hidden; /* Hide overflow content */
  white-space: normal; /* Allow text to wrap */
}
.show-delete-template-popup {
}

.parent-wrap {
  display: flex;
  gap: 15px;
}

.header-container {
  display: flex;
  flex-direction: column;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-top {
  border-radius: 5px;
}

.filter-input {
  width: 100%;
  margin-top: 0.5rem;
  box-sizing: border-box;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 5px;
}

.filter-icon {
  transition: transform 0.3s ease;
}

.filter-icon.rotated {
  transform: rotate(90deg);
}
</style>
